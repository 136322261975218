import React       from 'react';
import API         from 'API';
import _           from 'lodash';
import { connect } from 'react-redux';

import MediaTable              from 'Components/Common/DataTables/MediaTable';
import SearchMultiSelect       from 'Components/Common/Selects/SearchMultiSelect';
import AutoCompleteMultiSelect from 'Components/Common/Selects/AutoCompleteMultiSelect';
import AutoCompleteSelect      from 'Components/Common/Selects/AutoCompleteSelect';
import LoadingCircle           from 'Components/Common/LoadingCircle/LoadingCircle';

import { Grid, FormControl, Button, TextField } from '@material-ui/core';

import { clenyDate } from 'Helpers/TimeHelper';
import { colors } from 'Helpers/ColourHelper';

import { closeDialog }        from 'Actions/Dialog/Dialog';
import { deploySnackBar }     from 'Actions/SnackBar/SnackBar';
import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { Typography } from '@material-ui/core';

const initialState = {
    formData:   {
        customer:       null,
        orderIds:       [],
        quoteIds:       [],
        type:           '',
        files:          [],
        location:       null,
        desc:           null,
        source:         'Engineer Uploads',
        serialNumbers:  [],
    },
    lists:      {
        customers:     [],
        orders:        [],
        quotes:        [],
        files:         [],
        serialNumbers: [],
    },
    isLoading:  true
}

class LinkToOrderDialog extends React.Component {
    constructor(props){
        super(props)
        this.state = initialState;
        this.state.lists.files = _.map(_.filter(this.props.files, i => !i.order), f => ({...f, checked: false}))
        this.state.formData.desc = this.props.serialNumber;
    }   

    componentDidMount(){
        this.getCustomers();
        this.getMediaTypes();
    }

    getMediaTypes = () => {
        API.get('/parts/catagories')
        .then(res => {
            this.setState({
                lists: {
                    ...this.state.lists,
                    types: _.map(res.data, i => _.assign({value: i.pc_name, label: i.pc_name})),
                }
            });
        });
    }

    getCustomers = () => {
        API.get('/customers')
        .then(res =>{
            this.setState({
                lists: {
                    ...this.state.lists,
                    customers: _.map(res.data, c => _.assign({
                        value: c.cust_id,
                        label: `${c.cust_name} | CS${c.cust_id}`
                    })),
                },
                isLoading: false
            }, () => {
                if (this.props.site) this.getCustomerFromSite();
            })
        })
    }

    getCustomerFromSite = () => {
        let site = this.props.site.split(',')[0];
        API.get('/customers/addresses', {params: {keywords: site}})
        .then(res => {
            this.setState({
                formData: {
                    ...this.state.formData,
                    customer: res.data[0].address_customer_id,
                }
            })
        })
    }

    handleSelectChange = f => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [f]: e && e.value
            }
        })
    }

    handleMultiSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            }
        });
    }

    handleMultiCreateSelectChange = fieldName => selectedOptions => {
        let values = selectedOptions ? selectedOptions.map(a => a.value) : [];
        this.setState({
            formData: {
                ...this.state.formData,
                [fieldName]: values
            },
            lists: {
                ...this.state.lists,
                [fieldName]: selectedOptions ? selectedOptions : []
            }
        });
    }

    handleChange = f => e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [f]: e.target.value
            }
        })
    }

    handleCheck = item => e => {

        let files = this.state.lists.files;
        files[_.findIndex(this.state.lists.files, {euf_id: item.euf_id})].checked = !item.checked;
        this.setState({
            formData:{
                ...this.state.formData,
                files
            }
        }, () => {
            this.setState({
                formData:{
                    ...this.state.formData,
                    files: _.map(_.filter(files, i => i.checked), i => i.euf_id)
                }
            })
        });
    }

    linkToOrder = () => {
        API.post('/sales/orders/engineerMedia', this.state.formData)
        .then(res => {
            this.props.deploySnackBar('success', 'File Linked To Order');
            this.props.closeDialog();
            this.props.onClose()
        });
    }

    handleSearchChange = (e) => {
        this.setState({
            searchFormData: {
                ...this.state.searchFormData,
                keywords: e.target.value
            }
        })
    }

    render(){
        const { lists, isLoading, formData, searchFormData } = this.state;

        if (isLoading) return (<LoadingCircle/>)
        
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>Site Name: {this.props.site}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="none">
                        <AutoCompleteSelect 
                            name='customer'
                            label='Customer *'
                            options={lists.customers}
                            value={formData.customer}
                            onChange={this.handleSelectChange('customer')}
                            noClear
                        />
                    </FormControl>
                </Grid>
                {formData.customer && [
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="none">
                            <SearchMultiSelect 
                                name='order'
                                label='Order *'
                                value={formData.orderIds}
                                onChange={this.handleMultiSelectChange('orderIds')}
                                url={`/customers/${formData.customer}/sales/orders`}
                                refactor={{
                                    value: 'order_id',
                                    label: i => `${clenyDate(i.order_date)} | ${i.full_reference} | ${i.order_delivery_cust_address_name}`
                                }}
                                searchParams={{
                                    limit:  5,
                                    select: ['order_id','full_reference', 'order_delivery_cust_address_name', 'order_date']
                                }}
                                noClear
                            />
                        </FormControl>
                    </Grid>,
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="none">
                            <SearchMultiSelect 
                                name='quotations'
                                label='Quotations *'
                                value={formData.quoteIds}
                                onChange={this.handleMultiSelectChange('quoteIds')}
                                url={`/customers/${this.state.formData.customer}/sales/quotations`}
                                refactor={{
                                    value: 'quote_id',
                                    label: i => `${clenyDate(i.quote_date)} | ${i.full_reference} | ${i.quote_delivery_cust_address_name}`
                                }}
                                searchParams={{
                                    limit:  5,
                                    select: ['quote_id','full_reference','quote_delivery_cust_address_name','quote_date']
                                }}
                                noClear
                            />
                        </FormControl>
                    </Grid>,
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="none">
                            <AutoCompleteSelect
                                label="Product Type *"
                                options={_.sortBy(lists.types, i=> i.label)}
                                value={formData.type}
                                onChange={this.handleSelectChange('type')}
                                noClear
                            />
                        </FormControl>
                    </Grid>,
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="none">
                            <TextField
                                label="Location *"
                                value={formData.location}
                                onChange={this.handleChange('location')}
                            />
                        </FormControl>
                    </Grid>,
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="none">
                            <TextField
                                label="Description *"
                                value={formData.desc}
                                onChange={this.handleChange('desc')}
                            />
                        </FormControl>
                    </Grid>,
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="none">
                            <AutoCompleteMultiSelect
                                label="Serial Numbers"
                                value={formData.serialNumbers}
                                options={lists.serialNumbers}
                                onChange={this.handleMultiCreateSelectChange('serialNumbers')}
                                noClear
                                creatable
                            />
                        </FormControl>
                    </Grid>,
                    <Grid item xs={12}>
                        <MediaTable
                            config={{
                                key: 'euf_id',
                                disableMediaPreview: true,
                            }}
                            media='full_url'
                            fields={[ 
                                {
                                    heading: 'Linked Orders',
                                    field:   rowData => _.sumBy(rowData.engineer_media?.descriptions, i => i.orders.length) > 0 ?
                                        <Grid container>
                                            {_.map(rowData.engineer_media?.descriptions, i => 
                                                _.map(i.orders, o => <>{o.order_reference}<br/></>)    
                                            )}
                                        </Grid> : <><br/>None Linked</>,
                                },
                                {
                                    heading: 'Linked Quotes',
                                    field:   rowData => _.sumBy(rowData.engineer_media?.descriptions, i => i.quotes.length) > 0 ?
                                        <Grid container>
                                            {_.map(rowData.engineer_media?.descriptions, i => 
                                                _.map(i.quotes, o => <>{o.quote_reference}<br/></>)    
                                            )}
                                        </Grid> : <><br/>None Linked</>,
                                },
                                { actions: rowData => ([ 
                                        { 
                                            checkBox: true,
                                            onCheck:  this.handleCheck,
                                            checked:  rowData.checked,
                                            disabled: !rowData.checked && 
                                                _.find(rowData?.engineer_media?.descriptions, _i => 
                                                    _.find(_i.quotes, __i => 
                                                        _.find(formData.quoteIds, ___i => parseInt(___i) === parseInt(__i.quote_id))    
                                                    ) ||
                                                    _.find(_i.orders, __i => 
                                                        _.find(formData.orderIds, ___i => parseInt(___i) === parseInt(__i.order_id))    
                                                    )
                                                ) 
                                        }    
                                    ])
                                }
                            ]}
                            items={lists.files}
                        />
                    </Grid>
                ]}
                {
                    _.find(lists.files, i => 
                        i.checked && _.find(i?.engineer_media?.descriptions, _i => 
                            _.find(_i.orders, __i => 
                                _.find(formData.orderIds, ___i => parseInt(___i) === parseInt(__i.order_id))    
                            )
                        )   
                    ) &&
                    <Grid item xs={12} className='buttonRow'>
                        <Typography variant='caption' style={{color: colors.red}}>
                            Order is already linked to some of the images
                        </Typography>
                    </Grid>
                }
                {
                    _.find(lists.files, i => 
                        i.checked && _.find(i?.engineer_media?.descriptions, _i => 
                            _.find(_i.quotes, __i => 
                                _.find(formData.quoteIds, ___i => parseInt(___i) === parseInt(__i.quote_id))    
                            )
                        )   
                    ) &&
                        <Grid item xs={12} className='buttonRow'>
                            <Typography variant='caption' style={{color: colors.red}}>
                                Quote is already linked to some of the images
                            </Typography>
                        </Grid>
                }
                <Grid item xs={12} className='buttonRow'>
                    <Button variant='outlined' onClick={this.props.closeDialog}>Cancel</Button>
                    <Button variant='contained' color='primary' 
                            disabled={!formData.customer || (!formData.orderIds.length && !formData.quoteIds.length) || !formData.type || !formData.location || !formData.desc || !formData.files.length ||
                                _.find(lists.files, i => 
                                    i.checked && _.find(i?.engineer_media?.descriptions, _i => 
                                        _.find(_i.quotes, __i => 
                                            _.find(formData.quoteIds, ___i => parseInt(___i) === parseInt(__i.quote_id))    
                                        ) ||
                                        _.find(_i.orders, __i => 
                                            _.find(formData.orderIds, ___i => parseInt(___i) === parseInt(__i.order_id))    
                                        )
                                    )   
                                )
                            }
                            onClick={() => this.props.deployConfirmation(
                                "Are you sure you want to link these images to this order?",
                                'Link Images To Order',
                                this.linkToOrder
                            )}
                        >Link</Button>
                </Grid>
            </Grid>
        ) 
    }   
}

const mapDispatchToProps = dispatch => ({
    closeDialog:        ()                        => dispatch(closeDialog()),
    deployConfirmation: (message, title, success) => dispatch(deployConfirmation(message, title, success)),
    deploySnackBar:     (variant, body)           => dispatch(deploySnackBar(variant, body)),
})

export default connect(null, mapDispatchToProps)(LinkToOrderDialog);

